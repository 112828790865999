import React from "react";
import Header from "./header";

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
    <Header
      siteTitle={siteTitle}
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      showNav={showNav}
    />
    <footer className="fixed w-full bottom-0 inset-x-0 h-10 bg-white text-gray-800 text-center flex justify-center items-center py-4 font-medium">
      wanna have a chat ?   <a class="font-bold text-purple-500 underline hover:text-purple-900 pl-1" href="mailto:tzimm.pro@gmail.com">  drop me a line </a>
    </footer>
  </>
);

export default Layout;
